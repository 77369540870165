<template>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field  v-model="date" :label="label" :rules="rules"  :dense="dense" :outlined="outlined"  readonly v-bind="attrs"
                v-on="on"></v-text-field>
        </template>
        <v-date-picker  hide-details="auto" v-model="date" scrollable locale="ES-ve">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
                Cancelar
            </v-btn>
            <v-btn text color="primary" @click="select()">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import { onBeforeMount, ref,watch } from '@vue/composition-api'
import moment from 'moment'
export default {
    props: {
        value: String,
        dense:Boolean, 
        outlined:Boolean, 
        label:String,
        rules:Array
    },
    setup(props, contex) { 
        
        const modal = ref(false)
        const date = ref(null)
        const dialog = ref(null)
        onBeforeMount(()=>{ 
            date.value = props.value  
            //dialog.value.save(date.value)
        })
        
        watch(props, () => {  
            date.value = props.value  
            dialog.value.save(date.value)
        }) 
        
        const select=()=> { 
            dialog.value.save(date.value)
            contex.emit("input", date.value)

        }
        return {
            modal,
            date,
            select,
            dialog
        }
    },
}
</script>