//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ProductoServices {
    Consultar(
        datos
    ) {
        return http.get("producto/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarPaginado(
        datos
    ) {
        return http.get("producto/consultar/paginado", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }



    ConsultarContadorCodigoBarra(
        datos
    ) {
        return http.get("producto/consultar/contadorcodigobarra", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    Actualizar(
        idUsuario,
        Data
    ) {
        const datos = {
            idUsuario,
            Data
        }
        console.log(datos)
        return http.post("/producto/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    GenerarCodigoBarra(
        idUsuario,
        idContadorCodigoBarra
    ) {
        const datos = {
            idContadorCodigoBarra
        }
        console.log(datos)
        return http.post("/producto/generar/codigobarra", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                } s
            });
    }

    ExportarExcel(
        datos
    ) {
        return http.get("producto/consultar/excel", { params: datos })
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
    }

}


export default new ProductoServices();