<template>
    <div>
        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Producto" item-value="id" item-text="nombre"
            :dense="outlined" :outlined="outlined" :loading="cargando" @keyup="Buscar()" :search-input.sync="search" :rules="rules" search-input="false" >
            <template slot="selection" slot-scope="data">
                {{ data.item.nombre }}           
            </template>
            <template slot="item" slot-scope="data">
                 {{ data.item.nombre }}  
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import ProductoServices from '@/api/servicios/ProductoServices'
export default {
    props: {
        value:String,
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        padre: Number,
        rules:Array
    },
    setup(props,contex){
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)
        const search = ref(null)
        watch(props, () => {
            select.value = props.value
            Buscar()
        })
        const Buscar = () => {
            if(search.value != null){
                var nombre = search.value.toUpperCase()
            }else{
                nombre = "";
            }
            if ((search.value != undefined && search.value != null || select.value != -1)) {
                if(nombre.length > 3 || select.value){
                    setTimeout(() => {
                        cargando.value = true;
                        ProductoServices.Consultar({Id: select.value, Nombre: nombre})
                            .then(response => {
                                if(response.data.estatus == true){
                                    if(response.data.datos != null){
                                        lista.value = response.data.datos
                                        cargando.value = false
                                    }
                                }
                            })
                            .catch(error => {
                                console.log(error)
                                cargando.value = false
                            })
                            .finally(() => {
                                cargando.value = false
                            })
                    },1000)
                }else{
                    lista.value = [];
                }
            }
        }
        watch(select, () => {
            if (props.jsonRespueta == true) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input',
                    valor == undefined ? null : valor
                )
            } else {
                contex.emit('input',
                    select.value
                )
            }

        })
        // onBeforeMount(() => {
        //     Buscar()
        //     if (props.default != undefined) {
        //         select.value = props.default
        //     }
        // })
        const selectId = (id) => {
            select.value = id
        }
        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            search,
            selectId,
            Buscar
        }
    }
}
</script>